import Slider from "react-slick";
import Imgtestimonial from '../../assets/images/testimonial.png';
import IconStar from '../../assets/images/star.png';
import ImgUserAvtar from '../../assets/images/user-avtar.png';

const Testimonial = () =>{

  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    className: "slick-col testimonial-arrows",
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div className="testimonial-section section-gap">
        <div className="container">
          <div className="testimonial-wrapper">
            <div className="row">
              <div className="col col-12 col-md-6 image-block">
                <div className="has-img">
                  <img src={Imgtestimonial} alt="Testimonial Image" title='Testimonial Image' width={597} height={419} />
                </div>
              </div>
              <div className="col col-12 col-md-6 text-left testimonial-inner">
                <h2>Meet Client Satisfaction after working with us</h2>
                <Slider {...settings}>
                  <div className="testimonial-item">
                    <p className='testimonial-heading'>Doesn’t feel like an agency</p>
                    <div className="d-flex align-items-center rating-wrapper">
                      <i className="icon">
                        <img src={IconStar} alt="Star" title='1' width={20} height={20} />
                      </i>
                      <i className="icon">
                        <img src={IconStar} alt="Star" title='2' width={20} height={20} />
                      </i>
                      <i className="icon">
                        <img src={IconStar} alt="Star" title='3' width={20} height={20} />
                      </i>
                      <i className="icon">
                        <img src={IconStar} alt="Star" title='4' width={20} height={20} />
                      </i>
                      <i className="icon">
                        <img src={IconStar} alt="Star" title='5' width={20} height={20} />
                      </i>
                    </div>
                    <p className='testimonial-des'>Ultricies amet vulputate nulla in elit elit orci vitae tortor. Nunc ut pretium lectus aliquet varius pretium. Nec convallis morbi sapien aliquet augue. Nulla nulla integer pellentesque neque cum amet, turpis lacus, massa. Eu velit nunc adipiscing tincidunt blandi.</p>
                    <div className="d-flex align-items-center author-wrapper">
                      <img src={ImgUserAvtar} alt="Gwen Stacy" title='Gwen Stacy' width={60} height={60} />
                      <div className="author-info">
                        <p>Gwen Stacy</p>
                        <span>Cloudexter</span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <p className='testimonial-heading'>Doesn’t feel like an agency</p>
                    <div className="d-flex align-items-center rating-wrapper">
                      <i className="icon">
                        <img src={IconStar} alt="Star" title='1' width={20} height={20} />
                      </i>
                      <i className="icon">
                        <img src={IconStar} alt="Star" title='2' width={20} height={20} />
                      </i>
                      <i className="icon">
                        <img src={IconStar} alt="Star" title='3' width={20} height={20} />
                      </i>
                      <i className="icon">
                        <img src={IconStar} alt="Star" title='4' width={20} height={20} />
                      </i>
                      <i className="icon">
                        <img src={IconStar} alt="Star" title='5' width={20} height={20} />
                      </i>
                    </div>
                    <p className='testimonial-des'>Ultricies amet vulputate nulla in elit elit orci vitae tortor. Nunc ut pretium lectus aliquet varius pretium. Nec convallis morbi sapien aliquet augue. Nulla nulla integer pellentesque neque cum amet, turpis lacus, massa. Eu velit nunc adipiscing tincidunt blandi.</p>
                    <div className="d-flex align-items-center author-wrapper">
                      <img src={ImgUserAvtar} alt="Gwen Stacy" title='Gwen Stacy' width={60} height={60} />
                      <div className="author-info">
                        <p>Gwen Stacy</p>
                        <span>Cloudexter</span>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

};
export default Testimonial;