import React from 'react';
import { Link } from 'react-router-dom';
import ImgYogi from '../../assets/images/yogi.png';
import { ReactComponent as IconStar } from '../../assets/icons/star.svg';
import ImgReview from '../../assets/images/review.png';

const ShopDetailsMain = () => {

  return (
    <>
      <div className='shop-detail-page section-gap'>
        <div className="container">
          <div className="m-t-60 shop-details-main">
            <div className="row">
              <div className="col col-12 col-lg-4">
                <div className="shop-info-wrapper">
                  <img src={ImgYogi} className='shop-img' alt='Shop Thumb' title='Shop Thumb' width={505} height={408} />
                  <div className="shop-info">
                    <h5>Yogi Pan</h5>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
                  </div>
                </div>
              </div>
              <div className="col col-12 col-lg-8">
                <div className="review-wrapper">
                  <div className="review-heading">
                    <h5>Rerview</h5>
                    <IconStar />
                    <p>4.6/5</p>
                  </div>
                  <div className="review-listing">
                    <div className="d-flex review-item">
                      <div className="has-img">
                        <img src={ImgReview} alt="Titus Kitamura" title='Titus Kitamura' width={48} height={48} />
                      </div>
                      <div className="msg-info">
                        <p className='m-b-0 vendor-title'>Titus Kitamura</p><span className='msg-time'>5h</span>
                        <p className='m-b-8 meg-highlight'>omg, this is amazing</p>
                        <span className='satisfied'>Satisfied</span>
                        <div className="reviews">
                          <IconStar />
                          <p>4.6/5</p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex review-item">
                      <div className="has-img">
                        <img src={ImgReview} alt="Titus Kitamura" title='Titus Kitamura' width={48} height={48} />
                      </div>
                      <div className="msg-info">
                        <p className='m-b-0 vendor-title'>Titus Kitamura</p><span className='msg-time'>5h</span>
                        <p className='m-b-8 meg-highlight'>omg, this is amazing</p>
                        <span className='satisfied'>Satisfied</span>
                        <div className="reviews">
                          <IconStar />
                          <p>4.6/5</p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex review-item">
                      <div className="has-img">
                        <img src={ImgReview} alt="Titus Kitamura" title='Titus Kitamura' width={48} height={48} />
                      </div>
                      <div className="msg-info">
                        <p className='m-b-0 vendor-title'>Titus Kitamura</p><span className='msg-time'>5h</span>
                        <p className='m-b-8 meg-highlight'>omg, this is amazing</p>
                        <span className='satisfied'>Satisfied</span>
                        <div className="reviews">
                          <IconStar />
                          <p>4.6/5</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

};

export default ShopDetailsMain;