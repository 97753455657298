import { Link } from 'react-router-dom';
import ImgAd from '../../assets/images/ad.jpg';
const Blog = () => {

  return (
    <>
      <div className="blog-section with-bg section-gap">
        <div className="container">
          <div className="blog-wrapper">
            <div className="d-flex flex-wrap align-items-center m-b-32">
              <div className="text-left heading-wrapper">
                <h2 className="m-b-0 heading">WE'VE GOT IT BLOGGED</h2>
                <p>Your Best Practices Guide for all your local service needs</p>
              </div>
              <div className="m-l-auto action-block">
                <Link to="/" className="button-secondary">View All</Link>
              </div>
            </div>
            <div className="blog-items-list grid">
              <div className="blog-item grid-item1">
                <div className="m-b-10 blog-image">
                  <img src={ImgAd} alt="Cloud Computing a Revolution in the Digit..." title='Cloud Computing a Revolution in the Digit...' width={643} height={519} />
                </div>
                <p className='text-left m-t-16 m-b-10 blog-tagline'>Adhithiya Rajasekar - June 20, 2022</p>
                <h4 className='text-left sm-b-10 blogs-title'>
                  <Link to='/' className='m-b-20'>Cloud Computing a Revolution in the Digit...</Link>
                </h4>
                <p className='text-left blog-des'>In this digital era, businesses are moving towards better options for growth and success. Cloud computing is a smart...</p>
              </div>
              <div className="blog-item grid-item2">
                <div className="m-b-10 blog-image">
                  <img src={ImgAd} alt="Cloud Computing a Revolution in the Digit..." title='Cloud Computing a Revolution in the Digit...' width={305} height={218} />
                </div>
                <p className='text-left m-t-16 m-b-10 blog-tagline'>Adhithiya Rajasekar - June 20, 2022</p>
                <h4 className='text-left sm-b-10 blogs-title'>
                  <Link to='/' className='m-b-20'>Redesign channel website landng page</Link>
                </h4>
                <p className='text-left blog-des'>In this digital era, businesses are moving towards better options for growth and success. Cloud computing is a smart...</p>
              </div>
              <div className="blog-item grid-item3">
                <div className="m-b-10 blog-image">
                  <img src={ImgAd} alt="Cloud Computing a Revolution in the Digit..." title='Cloud Computing a Revolution in the Digit...' width={305} height={218} />
                </div>
                <p className='text-left m-t-16 m-b-10 blog-tagline'>Adhithiya Rajasekar - June 20, 2022</p>
                <h4 className='text-left sm-b-10 blogs-title'>
                  <Link to='/' className='m-b-20'>New Locator App For  a New Company</Link>
                </h4>
                <p className='text-left blog-des'>In this digital era, businesses are moving towards better options for growth and success. Cloud computing is a smart...</p>
              </div>
              <div className="blog-item grid-item4">
                <div className="m-b-10 blog-image">
                  <img src={ImgAd} alt="Cloud Computing a Revolution in the Digit..." title='Cloud Computing a Revolution in the Digit...' width={305} height={218} />
                </div>
                <p className='text-left m-t-16 m-b-10 blog-tagline'>Adhithiya Rajasekar - June 20, 2022</p>
                <h4 className='text-left sm-b-10 blogs-title'>
                  <Link to='/' className='m-b-20'>Rental Rooms Web  App Platform </Link>
                </h4>
                <p className='text-left blog-des'>In this digital era, businesses are moving towards better options for growth and success. Cloud computing is a smart...</p>
              </div>
              <div className="blog-item grid-item5">
                <div className="m-b-10 blog-image">
                  <img src={ImgAd} alt="Cloud Computing a Revolution in the Digit..." title='Cloud Computing a Revolution in the Digit...' width={305} height={218} />
                </div>
                <p className='text-left m-t-16 m-b-10 blog-tagline'>Adhithiya Rajasekar - June 20, 2022</p>
                <h4 className='text-left sm-b-10 blogs-title'>
                  <Link to='/' className='m-b-20'>The TOP 10 Culinary Institutes in India</Link>
                </h4>
                <p className='text-left blog-des'>In this digital era, businesses are moving towards better options for growth and success. Cloud computing is a smart...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

};
export default Blog;