import React from "react";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { useRoutes } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Router } from "./router/routing";
import Header from "./components/common/header/header";
import Footer from "./components/common/footer/footer";

function App() {

  const routing = useRoutes(Router);
  return (
    <div className="App">
      <Header />
      {routing}
      <Footer />
    </div>
  );

}

export default App;
