import { Link } from 'react-router-dom';
import {useState} from 'react';
import ImgHomeOffce from '../../assets/images/home-office.png';
import {ReactComponent as IconArrowDownRadius} from '../../assets/icons/arrow-down-radius.svg';
import {ReactComponent as IconAdd} from '../../assets/icons/icon-add.svg';

const LeftPanel = () => {

  const [isActive, setIsActive] = useState(false);
  const handleClick = () => {

    // 👇️ toggle
    setIsActive(isActive => !isActive);

    // 👇️ or set to true
    // setIsActive(true);

  };
  let toggleclass = isActive ? 'is-active' : '';

  return (
    <>
      <div className="text-left left-panel-wrapper">
        <div className={`d-flex align-items-center justify-content-between message-heading`} onClick={handleClick}>
          <h5 className='m-b-0 d-flex align-items-center'>
            Messages
            <i className="m-l-6 icon">
              <IconArrowDownRadius />
            </i>
            <span className='m-l-10 msg-counter'>12</span>
          </h5>
          <i className="icon icon-add">
            <IconAdd />
          </i>
        </div>

        <div className={`${toggleclass} message-listing`}>
          <div className="message-search-box">
            <input type="text" className='form-input' placeholder='Search messages'/>
          </div>
          <div className="d-flex message-item">
            <div className="has-img">
              <img src={ImgHomeOffce} alt="A-1 Pan" title='A-1 Pan' width={48} height={48} />
            </div>
            <div className="msg-info">
              <p className='m-b-0 vendor-title'>A-1 Pan</p><span className='msg-time'>12m</span>
              <p className='m-b-8 meg-highlight'>Haha oh man 🔥</p>
              <span className='not-satisfy'>Not Satisfy</span>
            </div>
          </div>
          <div className="d-flex message-item active">
            <div className="has-img">
              <img src={ImgHomeOffce} alt="A-1 Pan" title='A-1 Pan' width={48} height={48} />
            </div>
            <div className="msg-info">
              <p className='m-b-0 vendor-title'>Raj electric</p><span className='msg-time'>24m</span>
              <p className='m-b-8 meg-highlight'>woohoooo</p>
              <span className='satisfied'>Not Satisfy</span>
            </div>
          </div>
          <div className="d-flex message-item">
            <div className="has-img">
              <img src={ImgHomeOffce} alt="A-1 Pan" title='A-1 Pan' width={48} height={48} />
            </div>
            <div className="msg-info">
              <p className='m-b-0 vendor-title'>A-1 Pan</p><span className='msg-time'>12m</span>
              <p className='m-b-8 meg-highlight'>Haha that's terrifying 😂</p>
              <span className='satisfied'>Not Satisfy</span>
            </div>
          </div>
          <div className="d-flex message-item">
            <div className="has-img">
              <img src={ImgHomeOffce} alt="A-1 Pan" title='A-1 Pan' width={48} height={48} />
            </div>
            <div className="msg-info">
              <p className='m-b-0 vendor-title'>Raj electric</p><span className='msg-time'>24m</span>
              <p className='m-b-8 meg-highlight'>woohoooo</p>
              <span className='satisfied'>Not Satisfy</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );

};
export default LeftPanel;