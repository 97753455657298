import React from "react";
import BlogDetailsMain from "../components/Blog/blog-details";

const BlogDetails = () => {

  return (
    <>
      <BlogDetailsMain />
    </>
  );

};

export default BlogDetails;
