import React from 'react';
import LeftPanel from './left-panel';
import RightPanel from './right-panel';
const CategoryPanel = () => {

  return (
    <>
      <div className="category-panel-section section-gap">
        <div className="container">
          <div className="row">
            <div className="col col-12 col-md-4 col-xl-3">
              <LeftPanel />
            </div>
            <div className="col col-12 col-md-8 col-xl-9">
              <RightPanel />
            </div>
          </div>
        </div>
      </div>
    </>
  );

};

export default CategoryPanel;
