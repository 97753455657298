/* eslint-disable max-len */
// Only pages should be imported here

import Home from "../pages/Home";
import ContactUs from "../pages/ContactUs";
import Register from "../pages/Register";
import Login from "../pages/Login";
import ListOfVendor from "../pages/ListOfVendor";
import Messages from "../pages/Messages";
import Blog from "../pages/Blog";
import BlogDetails from "../pages/BlogDetails";
import ShopDetails from "../pages/ShopDetails";
import ListYourBusiness from "../pages/ListYourBusiness";

export const Router = [
  // Private Route like Products etc which can only access if user is logged in
  {
    path: "/",
    exact: true,
    children: [
      { path: "/", element: <Home /> },
      { path: "contact-us", element: <ContactUs /> }, // /contact-us
      { path: "list-of-vendor", element: <ListOfVendor /> }, // ========>  /list-of-vendor/
      { path: "messages", element: <Messages /> }, // =======>  /messages/
      { path: "blog", element: <Blog /> }, // =======>  /blog/
      { path: "blog-detail", element: <BlogDetails /> }, // =======>  /blog-detail/
      { path: "shop-detail", element: <ShopDetails /> }, // =======>  /shop-detail/
      { path: "list-your-business", element: <ListYourBusiness /> }, // =======>  /list-your-business/
    ],
  },
  // Public Route like Login, register, forgot pass which every user can see
  {
    path: "auth",
    exact: true,
    children: [
      { path: "sign-up", element: <Register /> }, // ========>  /auth/sign-up
      { path: "login", element: <Login /> }, // =======>  /auth/login
    ],
  },
];

// Notes

// 1) Make folder for every pages in components folder
// 2) In that folder one index.jsx file is compulsory
// 3) All files of that particular component will be imported in index.jsx
// 4) Make index file in every folder which is in component. (Example in Home folder of component)
// 5) index.jsx file will be imported in pages folder. You can give any name while import index.jsx
// 6) For example : Here we have Home component. If you click on it, you will redirect to component >> Home >> index.jsx
// 6) Only Pages will be imported here
