import { Link } from 'react-router-dom';
import ImgVendor from '../../assets/images/vendor-img.png';
import { ReactComponent as IconStar } from '../../assets/icons/star.svg';
import { ReactComponent as IconChat } from '../../assets/icons/chat.svg';
import { ReactComponent as IconCall } from '../../assets/icons/call.svg';
const RightPanel = () => {

  return (
    <>
      <div className="right-panel-wrapper">
        <div className="m-b-30 facets-container">
          <div className="d-flex align-items-center sort-on-top">
            <div className="total-results-counter">
              <p>48 Shops Near You</p>
            </div>
            <div className="d-flex align-items-center form-dropdown sort-by-dropdown">
              <label htmlFor="nearbyyou">Sort By</label>
              <div className="select-wrapper">
                <select name="Near By You" id="nearbyyou">
                  <option value="" disabled selected hidden>Near By You</option>
                  <option value="Ahm">Ahmedabad</option>
                  <option value="Jnd">Junagadh</option>
                </select>
              </div>
            </div>
            <div className="d-flex align-items-center form-dropdown show-dropdown">
              <label htmlFor="show">Show</label>
              <div className="select-wrapper">
                <select name="Near By You" id="show">
                  <option value="" disabled selected hidden>12</option>
                  <option value="Ahm">5</option>
                  <option value="Jnd">12</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="vendor-list-wrapper">
          <div className="d-flex list-item">
            <div className="image-wrapper">
              <img src={ImgVendor} alt="Vendor Image" title='Vendor Image' width={251} height={228} />
              <span className="label-location-distance">
                Near 3km
              </span>
            </div>
            <div className="text-left vendor-info">
              <h6 className='m-b-16 vendor-title'>Creative Comforts Super Pack Bean Bag Manufacturers Retail Wholesale SALES AT C G ROAD</h6>
              <div className="d-flex align-items-center m-b-16 rating-block">
                <span className="m-r-10 rating">
                  3.9
                </span>
                <div className="d-flex rating-start">
                  <i className="icon">
                    <IconStar/>
                  </i>
                  <i className="icon">
                    <IconStar/>
                  </i>
                  <i className="icon">
                    <IconStar/>
                  </i>
                  <i className="icon">
                    <IconStar/>
                  </i>
                  <i className="icon star-no-fill">
                    <IconStar/>
                  </i>
                </div>
                <span className='m-l-10 rating-counter'>35 Rating</span>
              </div>
              <ul className="m-l-0 p-0 m-b-16 list-style-none d-flex align-items-center vendor-location">
                <li>
                Prabhat Chowk
                </li>
                <li>
                Ghatlodiya, Ahmedabad, Gujarat
                </li>
              </ul>
              <div className="d-flex button-wrapper">
                <Link to='/' className='d-flex m-r-15 button'><i className="icon"><IconChat/></i>Chat Now</Link>
                <Link to='/' className='d-flex button-secondary'><i className="icon"><IconCall/></i>Call Now</Link>
              </div>
            </div>
          </div>
          <div className="d-flex list-item">
            <div className="image-wrapper">
              <img src={ImgVendor} alt="Vendor Image" title='Vendor Image' width={251} height={228} />
              <span className="label-location-distance">
                Near 3km
              </span>
            </div>
            <div className="text-left vendor-info">
              <h6 className='m-b-16 vendor-title'>Creative Comforts Super Pack Bean Bag Manufacturers Retail Wholesale SALES AT C G ROAD</h6>
              <div className="d-flex align-items-center m-b-16 rating-block">
                <span className="m-r-10 rating">
                  3.9
                </span>
                <div className="d-flex rating-start">
                  <i className="icon">
                    <IconStar/>
                  </i>
                  <i className="icon">
                    <IconStar/>
                  </i>
                  <i className="icon">
                    <IconStar/>
                  </i>
                  <i className="icon">
                    <IconStar/>
                  </i>
                  <i className="icon star-no-fill">
                    <IconStar/>
                  </i>
                </div>
                <span className='m-l-10 rating-counter'>35 Rating</span>
              </div>
              <ul className="m-l-0 p-0 m-b-16 list-style-none d-flex align-items-center vendor-location">
                <li>
                Prabhat Chowk
                </li>
                <li>
                Ghatlodiya, Ahmedabad, Gujarat
                </li>
              </ul>
              <div className="d-flex button-wrapper">
                <Link to='/' className='d-flex m-r-15 button'><i className="icon"><IconChat/></i>Chat Now</Link>
                <Link to='/' className='d-flex button-secondary'><i className="icon"><IconCall/></i>Call Now</Link>
              </div>
            </div>
          </div>
          <div className="d-flex list-item">
            <div className="image-wrapper">
              <img src={ImgVendor} alt="Vendor Image" title='Vendor Image' width={251} height={228} />
              <span className="label-location-distance">
                Near 3km
              </span>
            </div>
            <div className="text-left vendor-info">
              <h6 className='m-b-16 vendor-title'>Creative Comforts Super Pack Bean Bag Manufacturers Retail Wholesale SALES AT C G ROAD</h6>
              <div className="d-flex align-items-center m-b-16 rating-block">
                <span className="m-r-10 rating">
                  3.9
                </span>
                <div className="d-flex rating-start">
                  <i className="icon">
                    <IconStar/>
                  </i>
                  <i className="icon">
                    <IconStar/>
                  </i>
                  <i className="icon">
                    <IconStar/>
                  </i>
                  <i className="icon">
                    <IconStar/>
                  </i>
                  <i className="icon star-no-fill">
                    <IconStar/>
                  </i>
                </div>
                <span className='m-l-10 rating-counter'>35 Rating</span>
              </div>
              <ul className="m-l-0 p-0 m-b-16 list-style-none d-flex align-items-center vendor-location">
                <li>
                Prabhat Chowk
                </li>
                <li>
                Ghatlodiya, Ahmedabad, Gujarat
                </li>
              </ul>
              <div className="d-flex button-wrapper">
                <Link to='/' className='d-flex m-r-15 button'><i className="icon"><IconChat/></i>Chat Now</Link>
                <Link to='/' className='d-flex button-secondary'><i className="icon"><IconCall/></i>Call Now</Link>
              </div>
            </div>
          </div>
          <div className="pagination-wrapper">
            <ul className='d-flex align-items-center justify-content-center m-b-0 m-l-0 p-l-0 m-t-35'>
              <li className='pagination-item'>
                <Link to='/'>1</Link>
              </li>
              <li className='pagination-item active'>
                <Link to='/'>2</Link>
              </li>
              <li className='pagination-item'>
                <Link to='/'>3</Link>
              </li>
              <li className='pagination-item'>
                <Link to='/'>4</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );

};
export default RightPanel;