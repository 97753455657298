import React from 'react';
import ImgContact from '../../assets/images/contact-img.png';

const ContactUs = () =>
{

  return (
    <>
      <div className="contact-us-mian">
        <div className="container">
          <div className="contact-us-wrapper">
            <div className="row reverse-col">
              <div className="col col-12 col-md-6">
                <div className="main-form">
                  <h4>Contact Us</h4>
                  <p>Want to get in touch? We’d love to hear from you. </p>
                  <form action="">
                    <div className="form-field">
                      <input type="text" placeholder='Name' className='form-input' />
                    </div>
                    <div className="form-field">
                      <input type="email" placeholder='Email' className='form-input' />
                    </div>
                    <div className="form-field">
                      <select name="Reasons" id="reasons" className='form-select'>
                        <option value="Reasons" selected disabled hidden style={{color: "red"}}>Reasons</option>
                        <option value="Reasons">Reasons</option>
                        <option value="Reasons">Notes</option>
                      </select>
                    </div>
                    <div className="form-field">
                      <textarea name="Message" id="message" cols="30" rows="4" placeholder='Message' className='form-input'></textarea>
                    </div>
                    <div className="m-t-30 form-action">
                      <button type='submit' className='button'>Submit</button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col col-12 col-md-6">
                <div className="has-img">
                  <img src={ImgContact} alt="Contact Us" title='Contact Us' width={570} height={463} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

};

export default ContactUs;