import React from "react";
import BlogList from "../components/Blog";

const Blog = () => {

  return (
    <>
      <BlogList />
    </>
  );

};

export default Blog;
