import Blog from "./blog";
import HeroBanner from "./hero-banner";
import ProgressIndicator from "./progress-indicator";
import SponsoredAd from "./sponsored-ad";
import Testimonial from './testimonial';
import CategoryPanel from "../category-panel/category-panel";

const HomeMain = () => {

  return (
    <>
      <HeroBanner />
      <ProgressIndicator />
      <CategoryPanel />
      <SponsoredAd />
      <Blog />
      <Testimonial />
    </>
  );

};

export default HomeMain;