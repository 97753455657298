import { Link } from 'react-router-dom';
import ImgHomeOffce from '../../assets/images/home-office.png';

const LeftPanel = () => {

  return (
    <>
      <div className="text-left left-panel-wrapper">
        <h5 className='m-b-28'>Distance</h5>
        <ul className="list-style-none left-panel-nav">
          <li className='list-item'>
            <Link to="/" className='d-flex align-items-center item-link'>
              Near By 1KM <span className='counter'>2</span>
            </Link>
          </li>
          <li className='list-item active'>
            <Link to="/" className='d-flex align-items-center item-link'>
              Near By 1.5KM <span className='counter'>48</span>
            </Link>
          </li>
          <li className='list-item'>
            <Link to="/" className='d-flex align-items-center item-link'>
              Near By 2KM <span className='counter'>14</span>
            </Link>
          </li>
          <li className='list-item'>
            <Link to="/" className='d-flex align-items-center item-link'>
              Near By 3KM <span className='counter'>15</span>
            </Link>
          </li>
          <li className='list-item'>
            <Link to="/" className='d-flex align-items-center item-link'>
              Near By 4KM <span className='counter'>22</span>
            </Link>
          </li>
          <li className='list-item'>
            <Link to="/" className='d-flex align-items-center item-link'>
              Near By 5KM <span className='counter'>1</span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );

};
export default LeftPanel;