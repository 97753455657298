import { Link } from "react-router-dom";

const RightPanel = () => {

  return (
    <>
      <div className="right-panel-wrapper">
        <ul className='d-flex list-style-none category-tabs'>
          <li className="category-tab-item active">
            <Link to="/" className="category-tab-link">
                Home Appliance Dealers
            </Link>
          </li>
          <li className="category-tab-item">
            <Link to="/" className="category-tab-link">
                Home / Office Services
            </Link>
          </li>
          <li className="category-tab-item">
            <Link to="/" className="category-tab-link">
                Home / Office Products
            </Link>
          </li>
        </ul>
        <div className="services-link-wrapper">
          <div className="heading-wrapper">
            <p className="heading">Home & Office Product Services</p>
          </div>
          <ul className="list-style-none">
            <li className="services-link-item active">
              <Link to="/" className="services-link">
                Ac Services & Repair
              </Link>
            </li>
            <li className="services-link-item">
              <Link to="/" className="services-link">
                EPABX Repairs
              </Link>
            </li>
            <li className="services-link-item">
              <Link to="/" className="services-link">
                EPABX Repairs
              </Link>
            </li>
            <li className="services-link-item">
              <Link to="/" className="services-link">
                Air Cooler Repair
              </Link>
            </li>
            <li className="services-link-item">
              <Link to="/" className="services-link">
                Fire Fighting Equipment AMC Services
              </Link>
            </li>
            <li className="services-link-item">
              <Link to="/" className="services-link">
                Fire Fighting Equipment AMC Services
              </Link>
            </li>
            <li className="services-link-item">
              <Link to="/" className="services-link">
                TV Repair
              </Link>
            </li>
            <li className="services-link-item">
              <Link to="/" className="services-link">
                Driver Service
              </Link>
            </li>
            <li className="services-link-item">
              <Link to="/" className="services-link">
                Driver Service
              </Link>
            </li>
            <li className="services-link-item">
              <Link to="/" className="services-link">
                Washing Machine Repairs
              </Link>
            </li>
            <li className="services-link-item">
              <Link to="/" className="services-link">
                Cleaning Servies
              </Link>
            </li>
            <li className="services-link-item">
              <Link to="/" className="services-link">
                Cleaning Servies
              </Link>
            </li>
          </ul>
        </div>
        <div className="services-link-wrapper">
          <div className="heading-wrapper">
            <p className="heading">Home & Office Product Services</p>
          </div>
          <ul className="list-style-none">
            <li className="services-link-item active">
              <Link to="/" className="services-link">
                Ac Services & Repair
              </Link>
            </li>
            <li className="services-link-item">
              <Link to="/" className="services-link">
                EPABX Repairs
              </Link>
            </li>
            <li className="services-link-item">
              <Link to="/" className="services-link">
                EPABX Repairs
              </Link>
            </li>
            <li className="services-link-item">
              <Link to="/" className="services-link">
                Air Cooler Repair
              </Link>
            </li>
            <li className="services-link-item">
              <Link to="/" className="services-link">
                Fire Fighting Equipment AMC Services
              </Link>
            </li>
            <li className="services-link-item">
              <Link to="/" className="services-link">
                Fire Fighting Equipment AMC Services
              </Link>
            </li>
            <li className="services-link-item">
              <Link to="/" className="services-link">
                TV Repair
              </Link>
            </li>
            <li className="services-link-item">
              <Link to="/" className="services-link">
                Driver Service
              </Link>
            </li>
            <li className="services-link-item">
              <Link to="/" className="services-link">
                Driver Service
              </Link>
            </li>
            <li className="services-link-item">
              <Link to="/" className="services-link">
                Washing Machine Repairs
              </Link>
            </li>
            <li className="services-link-item">
              <Link to="/" className="services-link">
                Cleaning Servies
              </Link>
            </li>
            <li className="services-link-item">
              <Link to="/" className="services-link">
                Cleaning Servies
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );

};
export default RightPanel;