import { Link } from 'react-router-dom';
import ImgVendor from '../../assets/images/vendor-img.png';
import { ReactComponent as IconStar } from '../../assets/icons/star.svg';
import { ReactComponent as IconChat } from '../../assets/icons/chat.svg';
import { ReactComponent as IconCall } from '../../assets/icons/call.svg';
import { ReactComponent as IconMSGCall } from '../../assets/icons/msg-call.svg';
import { ReactComponent as IconSatisfied } from '../../assets/icons/satisfied.svg';
import { ReactComponent as IconSend } from '../../assets/icons/send.svg';
import { ReactComponent as IconShare } from '../../assets/icons/share.svg';
import { ReactComponent as IconNotSatisfied } from '../../assets/icons/not-satisfied.svg';
import ImgHomeOffce from '../../assets/images/home-office.png';
import ImgChatAvtar from '../../assets/images/user-avtar.png';
const RightPanel = () => {

  return (
    <>
      <div className="msg-right-panel right-panel-wrapper">
        <div className="d-flex justify-content-between align-items-center chat-header">
          <div className="d-flex">
            <div className="m-r-16 vendor-img has-img">
              <img src={ImgHomeOffce} alt="Raj electric" title='Raj electric' width={40} height={40} />
            </div>
            <div className="msg-info">
              <p className='m-b-0 vendor-title'>Raj electric</p>
              <p className='m-b-8 messenger-status'><span className='messenger-status-dot is-online'></span>Online</p>
            </div>
          </div>
          <div className="d-flex action-block">
            <Link to='/' className='m-r-15 d-flex align-items-center btn btn-not-satisfied'>
              <IconNotSatisfied className='m-r-8' />
              Not Satisfied
            </Link>
            <Link to='/' className='m-r-15 d-flex align-items-center btn btn-satisfied'>
              <IconSatisfied className='m-r-8' />
              Satisfied
            </Link>
            <Link to='/' className='d-flex align-items-center btn btn-call'>
              <IconMSGCall className='m-r-8' />
              Call
            </Link>
          </div>
        </div>
        <div className="chat-body">
          <div className="sender">
            <div className="profile-pic">
              <img src={ImgChatAvtar} alt="Chat Avtar" />
            </div>
            <div className="message-wrapper">
              <div className="message">
                <p>Yes This Model Available</p>
              </div>
              <div className="time">10:00 AM - 13th Oct 2022</div>
            </div>
          </div>
          <div className="receiver">
            <div className="profile-pic">
              <img src={ImgChatAvtar} alt="Chat Avtar" />
            </div>
            <div className="message-wrapper">
              <div className="message">
                <p>Yes This Model Available</p>
              </div>
              <div className="time">10:00 AM - 13th Oct 2022</div>
            </div>
          </div>
          <div className="sender">
            <div className="profile-pic">
              <img src={ImgChatAvtar} alt="Chat Avtar" />
            </div>
            <div className="message-wrapper">
              <div className="message">
                <p>Yes This Model Available</p>
              </div>
              <div className="time">10:00 AM - 13th Oct 2022</div>
            </div>
          </div>
          <div className="receiver">
            <div className="profile-pic">
              <img src={ImgChatAvtar} alt="Chat Avtar" />
            </div>
            <div className="message-wrapper">
              <div className="message">
                <p>Yes This Model Available</p>
              </div>
              <div className="time">10:00 AM - 13th Oct 2022</div>
            </div>
          </div>
          <div className="sender">
            <div className="profile-pic">
              <img src={ImgChatAvtar} alt="Chat Avtar" />
            </div>
            <div className="message-wrapper">
              <div className="message">
                <p>Yes This Model Available</p>
              </div>
              <div className="time">10:00 AM - 13th Oct 2022</div>
            </div>
          </div>
          <div className="receiver">
            <div className="profile-pic">
              <img src={ImgChatAvtar} alt="Chat Avtar" />
            </div>
            <div className="message-wrapper">
              <div className="message">
                <p>Yes This Model Available</p>
              </div>
              <div className="time">10:00 AM - 13th Oct 2022</div>
            </div>
          </div>
        </div>
        <div className='chat-footer send-message'>
          <div className="chat-box">
            <i className="icon icon-share">
              <Link to='#'>
                <IconShare />
              </Link>
            </i>
            <div className="input-wrapper">
              <i className="icon icon-send">
                <Link to='#'>
                  <IconSend /></Link>
              </i>
              <input type="text" name="chat-box" className='form-input' placeholder='Type a message' />
            </div>
          </div>
        </div>
      </div>
    </>
  );

};
export default RightPanel;