import React from 'react';

const Login = () =>
{

  return (
    <div>Login</div>
  );

};

export default Login;