import React from "react";
import HomeMain from "../components/Home";

const Home = () => {

  return (
    <>
      <HomeMain />
    </>
  );

};

export default Home;
