/* eslint-disable max-len */
import { Link } from 'react-router-dom';
import React from 'react';
import Slider from "react-slick";
import ImgHeroBanner from '../../assets/images/hero-banner.jpg';
import ImgHeroBanner2 from '../../assets/images/banner2.jpg';
import { ReactComponent as IconSearch } from '../../assets/icons/search-icon.svg';
import { ReactComponent as IconCamera } from '../../assets/icons/camera-icon.svg';
import { ReactComponent as IconLocation } from '../../assets/icons/location-icon.svg';

const HeroBanner = () => {

  const settings = {
    dots: true,
    infinite: false,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    className: "hero-banner-arrow",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      <div className="hero-banner-section">
        <Slider {...settings}>
          <div className="hero-banner-img">
            <img src={ImgHeroBanner} alt="Fast, FREE way to get experts." title='Fast, FREE way to get experts.' width={1920} height={600} />
          </div>
          <div className="hero-banner-img">
            <img src={ImgHeroBanner2} alt="Fast, FREE way to get experts." title='Fast, FREE way to get experts.' width={1920} height={600} />
          </div>
        </Slider>
        <div className="container">
          <div className="hero-banner-wrapper">
            <h2 className='m-b-40 text-center'>Fast, FREE way to get experts.</h2>
            <div className="d-flex flex-wrap align-items-center search-block-wrapper">
              <div className="search-input">
                <Link to="Javascript:void(0)" className='icon icon-search'><IconSearch /></Link>
                <input type="text" name="search" id="" placeholder='Find your service here' />
                <Link to="javascript:void(0)" className='icon icon-camera'><IconCamera /></Link>
              </div>
              <Link to="javascript:void(0)" className='icon icon-location'><IconLocation /></Link>
              <select name="address" id="">
                <option value="Ahmedabad">Ahmedabad</option>
                <option value="Junagadh">Junagadh</option>
                <option value="Jetpur">Jetpur</option>
              </select>
              {/* <Link to="#" className='button'>Find Experts</Link> */}
              {/* Button trigger modal */}
              <button type="button" class="button" data-bs-toggle="modal" data-bs-target="#exampleModal1">
                Find Experts
              </button>

              {/* Modal Verification code*/}
              <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h4 class="modal-title" id="exampleModalLabel">We have sent code on your <span>9924493640</span> Please Enter Code</h4>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <form>
                        <div class="mb-3">
                          <div className="d-flex justify-content-between otp-code-block">
                            <input type="text" class="form-control" placeholder='0' />
                            <input type="text" class="form-control" placeholder='0' />
                            <input type="text" class="form-control" placeholder='0' />
                            <input type="text" class="form-control" placeholder='0' />
                            <input type="text" class="form-control" placeholder='0' />
                            <input type="text" class="form-control" placeholder='0' />
                          </div>
                        </div>
                      </form>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="button">Submit</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

};

export default HeroBanner;
