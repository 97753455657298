import React from 'react';
import { Link } from 'react-router-dom';
import BlogHead from '../../assets/images/blog-head.jpg';
import ImgAd from '../../assets/images/ad.jpg';

const Blog = () => {

  return (
    <div className='blog-page'>
      <div className='m-b-80 page-heading page-heading--image'>
        <div className='bg-image'><img src={BlogHead} /></div>
        <h2>Blog</h2>
      </div>
      <div className='container'>
        <div className="m-b-32 text-left heading-wrapper">
          <h2 className="m-b-0 heading">Latest Blog</h2>
          <p>Your Best Practices Guide for all your local service needs</p>
        </div>
        <div className='blog-items section-gap'>
          <div className="blog-item">
            <div className="m-b-10 blog-image">
              <img src={ImgAd} alt="Cloud Computing a Revolution in the Digit..." title='Cloud Computing a Revolution in the Digit...' width={643} height={519} />
            </div>
            <p className='text-left m-t-16 m-b-10 blog-tagline'>Adhithiya Rajasekar - June 20, 2022</p>
            <h4 className='text-left sm-b-10 blogs-title'>
              <Link to='/blog-detail/' className='m-b-20'>Cloud Computing a Revolution in the Digit...</Link>
            </h4>
            <p className='text-left blog-des'>In this digital era, businesses are moving towards better options for growth and success. Cloud computing is a smart...</p>
          </div>

          <div className="blog-item">
            <div className="m-b-10 blog-image">
              <img src={ImgAd} alt="Cloud Computing a Revolution in the Digit..." title='Cloud Computing a Revolution in the Digit...' width={643} height={519} />
            </div>
            <p className='text-left m-t-16 m-b-10 blog-tagline'>Adhithiya Rajasekar - June 20, 2022</p>
            <h4 className='text-left sm-b-10 blogs-title'>
              <Link to='/' className='m-b-20'>Cloud Computing a Revolution in the Digit...</Link>
            </h4>
            <p className='text-left blog-des'>In this digital era, businesses are moving towards better options for growth and success. Cloud computing is a smart...</p>
          </div>

          <div className="blog-item">
            <div className="m-b-10 blog-image">
              <img src={ImgAd} alt="Cloud Computing a Revolution in the Digit..." title='Cloud Computing a Revolution in the Digit...' width={643} height={519} />
            </div>
            <p className='text-left m-t-16 m-b-10 blog-tagline'>Adhithiya Rajasekar - June 20, 2022</p>
            <h4 className='text-left sm-b-10 blogs-title'>
              <Link to='/' className='m-b-20'>Cloud Computing a Revolution in the Digit...</Link>
            </h4>
            <p className='text-left blog-des'>In this digital era, businesses are moving towards better options for growth and success. Cloud computing is a smart...</p>
          </div>

          <div className="blog-item">
            <div className="m-b-10 blog-image">
              <img src={ImgAd} alt="Cloud Computing a Revolution in the Digit..." title='Cloud Computing a Revolution in the Digit...' width={643} height={519} />
            </div>
            <p className='text-left m-t-16 m-b-10 blog-tagline'>Adhithiya Rajasekar - June 20, 2022</p>
            <h4 className='text-left sm-b-10 blogs-title'>
              <Link to='/' className='m-b-20'>Cloud Computing a Revolution in the Digit...</Link>
            </h4>
            <p className='text-left blog-des'>In this digital era, businesses are moving towards better options for growth and success. Cloud computing is a smart...</p>
          </div>

          <div className="blog-item">
            <div className="m-b-10 blog-image">
              <img src={ImgAd} alt="Cloud Computing a Revolution in the Digit..." title='Cloud Computing a Revolution in the Digit...' width={643} height={519} />
            </div>
            <p className='text-left m-t-16 m-b-10 blog-tagline'>Adhithiya Rajasekar - June 20, 2022</p>
            <h4 className='text-left sm-b-10 blogs-title'>
              <Link to='/' className='m-b-20'>Cloud Computing a Revolution in the Digit...</Link>
            </h4>
            <p className='text-left blog-des'>In this digital era, businesses are moving towards better options for growth and success. Cloud computing is a smart...</p>
          </div>

          <div className="blog-item">
            <div className="m-b-10 blog-image">
              <img src={ImgAd} alt="Cloud Computing a Revolution in the Digit..." title='Cloud Computing a Revolution in the Digit...' width={643} height={519} />
            </div>
            <p className='text-left m-t-16 m-b-10 blog-tagline'>Adhithiya Rajasekar - June 20, 2022</p>
            <h4 className='text-left sm-b-10 blogs-title'>
              <Link to='/' className='m-b-20'>Cloud Computing a Revolution in the Digit...</Link>
            </h4>
            <p className='text-left blog-des'>In this digital era, businesses are moving towards better options for growth and success. Cloud computing is a smart...</p>
          </div>

          <div className="blog-item">
            <div className="m-b-10 blog-image">
              <img src={ImgAd} alt="Cloud Computing a Revolution in the Digit..." title='Cloud Computing a Revolution in the Digit...' width={643} height={519} />
            </div>
            <p className='text-left m-t-16 m-b-10 blog-tagline'>Adhithiya Rajasekar - June 20, 2022</p>
            <h4 className='text-left sm-b-10 blogs-title'>
              <Link to='/' className='m-b-20'>Cloud Computing a Revolution in the Digit...</Link>
            </h4>
            <p className='text-left blog-des'>In this digital era, businesses are moving towards better options for growth and success. Cloud computing is a smart...</p>
          </div>

          <div className="blog-item">
            <div className="m-b-10 blog-image">
              <img src={ImgAd} alt="Cloud Computing a Revolution in the Digit..." title='Cloud Computing a Revolution in the Digit...' width={643} height={519} />
            </div>
            <p className='text-left m-t-16 m-b-10 blog-tagline'>Adhithiya Rajasekar - June 20, 2022</p>
            <h4 className='text-left sm-b-10 blogs-title'>
              <Link to='/' className='m-b-20'>Cloud Computing a Revolution in the Digit...</Link>
            </h4>
            <p className='text-left blog-des'>In this digital era, businesses are moving towards better options for growth and success. Cloud computing is a smart...</p>
          </div>
        </div>
      </div>
    </div>
  );

};

export default Blog;