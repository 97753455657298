import React from "react";
import ShopDetailsMain from "../components/ShopDetails/shop-details";

const ShopDetails = () => {

  return (
    <>
      <ShopDetailsMain />
    </>
  );

};

export default ShopDetails;
