import LeftPanel from "./left-panel";
import RightPanel from "./right-panel";
const ListOfVendorMain = () =>
{

  return (
    <>
      <div className="list-of-vendor-section m-t-80 section-gap">
        <div className="container">
          <div className="row">
            <div className="col col-12 col-lg-4 col-xl-3">
              <LeftPanel />
            </div>
            <div className="col col-12 col-lg-8 col-xl-9">
              <RightPanel />
            </div>
          </div>
        </div>
      </div>
    </>
  );

};

export default ListOfVendorMain;