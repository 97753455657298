import React from "react";
import LoginMain from "../components/Login";

const Login = () => {

  return (
    <>
      <LoginMain />
    </>
  );

};

export default Login;
