/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as HeaderLogo } from '../../../assets/icons/flash-screen-logo.svg';
import ImgUser from '../../../assets/images/user.jpg';
import { ReactComponent as IconMessage } from '../../../assets/icons/message.svg';
import { ReactComponent as IconNotification } from '../../../assets/icons/notification.svg';

const Header = () => {

  return (
    <header className='main-header header'>
      <div className="container">
        <div className="d-flex align-items-center justify-content-between header-wrapper">
          <button class="navbar-toggler mobile-view" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div className="logo-wrapper">
            <Link to="/">
              <HeaderLogo />
            </Link>
          </div>
          <nav class="navbar navbar-expand-lg">
            <div class="container-fluid">
              <div class="navbar-collapse nav-responsive" id="navbarSupportedContent">
                <ul class="navbar-nav m-0 me-auto mb-2 mb-lg-0">
                  <li className="nav-item active">
                    <Link to='/' className="nav-link">Home</Link>
                  </li>
                  <li className="nav-item">
                    <Link to='blog' className="nav-link">Blog</Link>
                  </li>
                  <li className="nav-item">
                    <Link to='about-us' className="nav-link">About us</Link>
                  </li>
                  <li className="nav-item">
                    <Link to='contact-us' className="nav-link">Contact us</Link>
                  </li>
                  <li className="nav-item">
                    <Link to='list-your-business' className="nav-link">List Your Business</Link>
                  </li>
                  <li className="show-for-mobile user-login nav-item">
                    <Link to='login' className="nav-link">Login</Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div className="d-flex align-items-center header-right-side-wrapper">
            <div className="d-flex m-r-55 icon-wrapper">
              <Link to="messages" className='icon-link m-r-15'>
                <i className="d-flex align-items-center justify-content-center icon icon-message">
                  <IconMessage />
                </i>
              </Link>
              <Link to="/" className='icon-link'>
                <i className="d-flex align-items-center justify-content-center icon icon-notification">
                  <IconNotification />
                </i>
              </Link>
            </div>
            <div className="user-login">
              <Link to="#exampleModal" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" className='d-flex align-items-center small-font'>
                Login
                <i className="m-l-6 icon">
                  <img src={ImgUser} alt="Login" title='Login' width={35} height={35} />
                </i>
              </Link>
            </div>
            {/* Modal */}
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h4 class="modal-title" id="exampleModalLabel">Find this from and Get Quotes from Best AC Service in Ahmedabad</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <form>
                      <div class="mb-3">
                        <input type="text" class="form-control" placeholder='Enter your name' />
                      </div>
                      <div class="mb-3">
                        <input type="email" class="form-control" placeholder='Enter Your Email ' />
                      </div>
                      <div class="mb-3">
                        <input type="text" class="form-control" placeholder='Enter Your Mobile Number* ' />
                      </div>
                    </form>
                  </div>
                  <div class="modal-footer">
                    <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal1" class="button">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </header>
  );

};

export default Header;
