import { Link } from 'react-router-dom';
import ImgHomeOffce from '../../assets/images/home-office.png';
import ImgHomeImprovement from '../../assets/images/home-improvement.png';

const LeftPanel = () => {

  return (
    <>
      <div className="left-panel-wrapper">
        <ul className="list-style-none left-panel-nav">
          <li className='category-item active'>
            <Link to="/" className='d-flex align-items-center category-link'>
              <i className="icon m-r-26">
                <img src={ImgHomeOffce} alt="Home & Office" title='Home & Office' width={56} height={56} />
              </i>
                Home & Office
            </Link>
          </li>
          <li className='category-item'>
            <Link to="/" className='d-flex align-items-center category-link'>
              <i className="icon m-r-26">
                <img src={ImgHomeImprovement} alt="Home Improvement" title='Home Improvement' width={56} height={56} />
              </i>
                Home Improvement
            </Link>
          </li>
          <li className='category-item'>
            <Link to="/" className='d-flex align-items-center category-link'>
              <i className="icon m-r-26">
                <img src={ImgHomeImprovement} alt="Properties & Rentals" title='Properties & Rentals' width={56} height={56} />
              </i>
                Properties & Rentals
            </Link>
          </li>
          <li className='category-item'>
            <Link to="/" className='d-flex align-items-center category-link'>
              <i className="icon m-r-26">
                <img src={ImgHomeImprovement} alt="Education & Training" title='Education & Training' width={56} height={56} />
              </i>
                Education & Training
            </Link>
          </li>
          <li className='category-item'>
            <Link to="/" className='d-flex align-items-center category-link'>
              <i className="icon m-r-26">
                <img src={ImgHomeImprovement} alt="Professional Services" title='Professional Services' width={56} height={56} />
              </i>
                Professional Services
            </Link>
          </li>
          <li className='category-item'>
            <Link to="/" className='d-flex align-items-center category-link'>
              <i className="icon m-r-26">
                <img src={ImgHomeImprovement} alt="Travel & Transport" title='Travel & Transport' width={56} height={56} />
              </i>
                Travel & Transport
            </Link>
          </li>
          <li className='category-item'>
            <Link to="/" className='d-flex align-items-center category-link'>
              <i className="icon m-r-26">
                <img src={ImgHomeImprovement} alt="Health & Wellness" title='Health & Wellness' width={56} height={56} />
              </i>
                Health & Wellness
            </Link>
          </li>
          <li className='category-item'>
            <Link to="/" className='d-flex align-items-center category-link'>
              <i className="icon m-r-26">
                <img src={ImgHomeImprovement} alt="Events" title='Events' width={56} height={56} />
              </i>
                Events
            </Link>
          </li>
          <li className='category-item'>
            <Link to="/" className='d-flex align-items-center category-link'>
              <i className="icon m-r-26">
                <img src={ImgHomeImprovement} alt="Events" title='Events' width={56} height={56} />
              </i>
                Events
            </Link>
          </li>
          <li className='category-item'>
            <Link to="/" className='d-flex align-items-center category-link'>
              <i className="icon m-r-26">
                <img src={ImgHomeImprovement} alt="Events" title='Events' width={56} height={56} />
              </i>
                Events
            </Link>
          </li>
        </ul>
      </div>
    </>
  );

};
export default LeftPanel;