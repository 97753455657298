import React from 'react';

const SignUp = () =>
{

  return (
    <div>SignUp</div>
  );

};

export default SignUp;