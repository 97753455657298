import React from "react";
import SignUpMain from "../components/Register";

const Register = () => {

  return (
    <>
      <SignUpMain />
    </>
  );

};

export default Register;
