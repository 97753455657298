import React from "react";
import SignUp from "./SignUp";

const SignUpMain = () => {

  return (
    <>
      <SignUp />
    </>
  );

};

export default SignUpMain;
