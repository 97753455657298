import React from "react";
import Blog from "./Blog";

const BlogList = () => {

  return (
    <>
      <Blog />
    </>
  );

};

export default BlogList;
