import { Link } from "react-router-dom";
import Slider from "react-slick";
import ImgAd from '../../assets/images/ad.jpg';
import ImgShop from '../../assets/images/shop-icon.jpg';

const SponsoredAd = () => {

  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    className: "slick-col",
    dotsClass: "button__bar",
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          arrows: false,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="sponsored-ad-section section-gap">
        <div className="container">
          <div className="sponsored-ad-wrapper">
            <div className="text-left m-b-26 heading-wrapper">
              <h3 className="m-b-0">Sponsored Ads</h3>
            </div>
            <div className="row">
              <Slider {...settings}>
                <div className="col col-3">
                  <div className="ad-item">
                    <div className="m-b-10 ad-image">
                      <img src={ImgAd} alt="Uttarayan Special Sale" title='Uttarayan Special Sale' width={312} height={226} />
                    </div>
                    <Link to='/' className='m-b-20 text-left ad-title'>Uttarayan Special Sale</Link>
                    <div className="d-flex text-left shop-action">
                      <div className="d-flex align-items-center shop-name">
                        <i className="m-r-10 icon">
                          <img src={ImgShop} alt="Om Kite" title='Om Kite' width={33} height={33} />
                        </i>
                        <p className='m-b-0'>Om Kite</p>
                      </div>
                      <div className="action-block">
                        <Link to="/" className='button-secondary'>Ads Details</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col col-3">
                  <div className="ad-item">
                    <div className="m-b-10 ad-image">
                      <img src={ImgAd} alt="Uttarayan Special Sale" title='Uttarayan Special Sale' width={312} height={226} />
                    </div>
                    <Link to='/' className='m-b-20 text-left ad-title'>Uttarayan Special Sale</Link>
                    <div className="d-flex text-left shop-action">
                      <div className="d-flex align-items-center shop-name">
                        <i className="m-r-10 icon">
                          <img src={ImgShop} alt="Om Kite" title='Om Kite' width={33} height={33} />
                        </i>
                        <p className='m-b-0'>Om Kite</p>
                      </div>
                      <div className="action-block">
                        <Link to="/" className='button-secondary'>Ads Details</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col col-3">
                  <div className="ad-item">
                    <div className="m-b-10 ad-image">
                      <img src={ImgAd} alt="Uttarayan Special Sale" title='Uttarayan Special Sale' width={312} height={226} />
                    </div>
                    <Link to='/' className='m-b-20 text-left ad-title'>Uttarayan Special Sale</Link>
                    <div className="d-flex text-left shop-action">
                      <div className="d-flex align-items-center shop-name">
                        <i className="m-r-10 icon">
                          <img src={ImgShop} alt="Om Kite" title='Om Kite' width={33} height={33} />
                        </i>
                        <p className='m-b-0'>Om Kite</p>
                      </div>
                      <div className="action-block">
                        <Link to="/" className='button-secondary'>Ads Details</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col col-3">
                  <div className="ad-item">
                    <div className="m-b-10 ad-image">
                      <img src={ImgAd} alt="Uttarayan Special Sale" title='Uttarayan Special Sale' width={312} height={226} />
                    </div>
                    <Link to='/' className='m-b-20 text-left ad-title'>Uttarayan Special Sale</Link>
                    <div className="d-flex text-left shop-action">
                      <div className="d-flex align-items-center shop-name">
                        <i className="m-r-10 icon">
                          <img src={ImgShop} alt="Om Kite" title='Om Kite' width={33} height={33} />
                        </i>
                        <p className='m-b-0'>Om Kite</p>
                      </div>
                      <div className="action-block">
                        <Link to="/" className='button-secondary'>Ads Details</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col col-3">
                  <div className="ad-item">
                    <div className="m-b-10 ad-image">
                      <img src={ImgAd} alt="Uttarayan Special Sale" title='Uttarayan Special Sale' width={312} height={226} />
                    </div>
                    <Link to='/' className='m-b-20 text-left ad-title'>Uttarayan Special Sale</Link>
                    <div className="d-flex text-left shop-action">
                      <div className="d-flex align-items-center shop-name">
                        <i className="m-r-10 icon">
                          <img src={ImgShop} alt="Om Kite" title='Om Kite' width={33} height={33} />
                        </i>
                        <p className='m-b-0'>Om Kite</p>
                      </div>
                      <div className="action-block">
                        <Link to="/" className='button-secondary'>Ads Details</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );

};
export default SponsoredAd;