import React from "react";
import Login from "./Login";

const LoginMain = () => {

  return (
    <>
      <Login />
    </>
  );

};

export default LoginMain;
